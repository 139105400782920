import React, { useEffect, useRef } from "react";
import PatientDetailsHeader from "../../../components/PatientDetailsHeader";
import {
  Typography,
  styled,
  TextField,
  Grid,
  Autocomplete,
  Modal,
  Box,
  Toolbar,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { Delete, Assignment } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getEMRId, postEMR, previewPMR } from "./EMRPage.slice";
import { submitPdf } from "../../../components/PMRPdf/pmrPdf.slice";
import { useNavigate } from "react-router-dom";
import { calculateBMI, convertDateFormat } from "../../../utils/utils";
import CustomizedDialogs from "../../../components/Dialog";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import CustomLoader from "../../../components/CustomLoader";
import { format } from "date-fns";
import SendPMR from "../SendPMR";
import CustomSnackbar from "../../../components/CustomSnackbar";
import "react-pdf";
// import "../node_modules/react-pdf/src/Page/AnnotationLayer.css";
// import "react-pdf/src/Page/TextLayer.css";
import RecorderComponent from "../../../components/RecorderComponent";
import {
  getAllTemplates,
  getTemplateDetails,
  getTemplateResponse,
} from "../../Dashboard/template.slice";
import { decodeHashData, encodeHashData } from "../../../utils/hashing";
import axios from "axios";
import EMRComponent from "../../../components/EMRComponent";
import { deepLink } from "../EMRPage/EMRPage.slice";
import GenerateInvoiceDialog from "../../../components/InvoiceGenerationConsentDialog";
// import medicineList from '../../../medicineList.json'
const isMobile = window.innerWidth < 600;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  bgcolor: "background.paper",
  border: "1px solid #696969",
  boxShadow: 24,
  padding: "0 16px 16px",
};

const PatientEMRWrapper = styled("div")(({ theme }) => ({
  // position: "absolute",
  padding: "20px 10px 2px",
  [theme.breakpoints.down("sm")]: {
    padding: "10px 4px 0",
  },
}));

const EMRFormWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  paddingRight: "15px",
  [theme.breakpoints.down("sm")]: {
    paddingRight: "8px",
  },
}));
const EMRFormInnerWrapper = styled("div")(({ theme }) => ({
  // height: "500px",
  // overflow: "scroll",
}));

const EMRFooter = styled("div")(({ theme }) => ({
  "&": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.primaryBlue}`,
    backgroundColor: "#b2d6f0",
    padding: theme.spacing(2, 8),
    position: "relative",
    bottom: 0,
    zIndex: 1,
  },
  [theme.breakpoints.down("sm")]: {
    padding: "8px 5px",
  },
}));

const PDFViewerWrapper = styled("div")(({ theme }) => ({
  flex: "1",
  // [theme.breakpoints.up("md")]: {
  //   height: "545px",
  // },
  [theme.breakpoints.down("md")]: {
    height: "auto",
    marginBottom: "0",
  },
}));

const PDFButtonWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: "16px",
  position: "sticky",
  bottom: 0,
  border: `1px solid ${theme.palette.primaryBlue}`,
  backgroundColor: "#b2d6f0",
  padding: theme.spacing(2, 2),
  zIndex: 1,
}));

const MobilePDFButtonWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: "16px",
  position: "sticky",
  bottom: 0,
  width: "100%",
  border: `1px solid ${theme.palette.primaryBlue}`,
  backgroundColor: "#b2d6f0",
  padding: theme.spacing(2, 2),
  zIndex: 1,
}));

const PrimaryButton = styled("button")(({ theme }) => ({
  "&": theme.typography.primaryButton,
  float: "right",
  [theme.breakpoints.down("sm")]: {
    padding: "5px 7px",
  },
}));

const SecondaryButton = styled("button")(({ theme }) => ({
  "&": theme.typography.secondaryButton,
  [theme.breakpoints.down("sm")]: {
    padding: "5px 10px",
  },
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  "&": theme.typography.sectionBody,
  marginBottom: theme.spacing(4),
}));

const RecordLayout = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input-MuiOutlinedInput-input": {
    textOverflow: "ellipsis",
  },
  [theme.breakpoints.up("md")]: {
    flex: 1,
  },
  [theme.breakpoints.down("md")]: {
    width: "140px",
  },
  // textAlign: "left",
  // padding: theme.spacing(3, 4),
  // border: `1px solid ${theme.palette.primaryGrey}`,
  // flex: 1,
  // // height: theme.spacing(13),
  // borderRadius: theme.spacing(1.5),
  // height: "min-content",
  // [theme.breakpoints.down("sm")]: {
  //   height: "max-content",
  //   padding: "10px 8px",
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  // },
  // "&.addMinWidth": {
  //   [theme.breakpoints.down("sm")]: {
  //     minWidth: "90px",
  //   },
  // },
}));

const TextBoxLayout = styled("div")(({ theme }) => ({
  flex: 1,
  // "& .MuiAutocomplete-root": {
  //   width: "110px",
  // },
  "&.desktopTextBoxLayout": {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "&.mobileTextBoxLayout": {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    "&.frequencyInput": {
      minWidth: "90px",
    },
  },
  "&.addMaxWidth": {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "90px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100px",
    },
  },
  "&.addMinWidth": {
    [theme.breakpoints.down("sm")]: {
      minWidth: "90px",
      ".MuiOutlinedInput-root": {
        padding: "6px",
      },
    },
  },
  ".MuiAutocomplete-input": {
    textOverflow: "clip",
  },
  "& .textareaAutoSizeStyle": {
    minWidth: "100%",
    maxWidth: "100%",
    maxHeight: "81px",
  },
}));

const RecordTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
}));

const NotesWrapper = styled("div")(({ theme }) => ({
  // "&": {
  //   display: "flex",
  // },
  flex: 1,
  ".desktop": {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const DeleteWrapper = styled("div")(({ theme }) => ({
  // flex: 1,
  display: "flex",
  alignItems: "center",
  ".mobile": {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const NotesField = styled(Assignment)(({ theme }) => ({
  height: "30px",
  width: "30px",
}));

const DeleteField = styled(Delete)(({ theme }) => ({
  height: "30px",
  width: "30px",
}));

// const PageTitle = styled(Typography)(({ theme }) => ({
//   "&": theme.typography.h1,
//   marginBottom: theme.spacing(2),
// }));
// const PageSubText = styled(Typography)(({ theme }) => ({
//   "&": theme.typography.h2,
//   marginBottom: theme.spacing(8),
// }));

const PdfDisplayWrapper = styled("div")(({ theme }) => ({
  // display: "flex",
  // alignItems: "center",
  // gap: theme.spacing(10),
}));

const BPWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  border: "1px solid rgba(0,0,0,0.23)",
  height: "51px",
  textAlign: "center",
}));
const RowWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(4),
}));
const DiastolicTextField = styled(TextField)(({ theme }) => ({
  "&.MuiFormControl-root > .MuiInputBase-root > fieldset": {
    border: "none",
  },
}));

const SystolicTextField = styled(TextField)(({ theme }) => ({
  "&.MuiFormControl-root > .MuiInputBase-root > fieldset": {
    border: "none",
  },
}));

const Divider = styled("div")(({ theme }) => ({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  fontSize: "18px",
  fontWeight: "bold",
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}

const PatientEMRDetails = (props) => {
  const { height, width } = useWindowDimensions();
  const [existingConditionsOpts, setExistingConditionOpts] = useState([]);
  const [symptomsOpts, setSymptomsOpts] = useState([]);
  const [examFindingsOpts, setExamFindingsOpts] = useState([]);
  const [diagnosisOpts, setDiagnosisOpts] = useState([]);
  const [medicationsOpts, setMedicationsOpts] = useState([]);
  const [labInvestigationsOpts, setLabInvestigationsOpts] = useState([]);
  const [medicalHistoryOpts, setMedicalHistoryOpts] = useState([]);
  const [existingConditions, setExistingCondition] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [medicalHistory, setMedicalHistory] = useState([]);
  const [examFindings, setExamFinding] = useState([]);
  const [diagnosis, setDiagnosis] = useState([]);
  const [medications, setMedications] = useState([]);
  const [labInvestigation, setLabInvestigation] = useState([]);
  const [prescriptionComment, setPrescriptionComment] = useState([]);
  const [advices, setAdvices] = useState([]);
  const pageSelected = sessionStorage.getItem("PageSelected");
  // const [showSeveritySymptomps, setShowSeveritySymptomps] = useState(false);
  // const [showMedicalHistory, setShowMedicalHistory] = useState(false);
  const [optionTextValues, setOptionTextValues] = useState({});
  const [existingConditionSpecs, setExistingConditionsSpecs] = useState({});
  const [symptomsSpecs, setSymptomsSpecs] = useState({});
  // const [medicalHistorySpecs, setMedicalHistorySpecs] = useState({});
  const [examinationSpecs, setExaminationSpecs] = useState({});
  const [diagnosisSpecs, setDiagnosisSpecs] = useState({});
  const [medicationsSpecs, setMedicationsSpecs] = useState({});
  const [labInvestigationSpecs, setLabInvestigationSpecs] = useState({});
  const [bodyMassIndex, setBodyMassIndex] = useState("");
  const medicalHistoryRef = useRef();
  const encounterDetail = decodeHashData("encounterDetail");
  const [pmrFinished, setPmrFinished] = useState(false);
  const [pdfData, setPdfData] = useState({});
  const [submitEMRPayload, setSubmitEMRPayload] = useState({});
  // const dataState = useSelector((state) => state);
  const [patientData, setPatientData] = useState({});
  const [step, setStep] = useState("create");
  const [number, setNumber] = useState("");
  const [symptomNumber, setSymptomNumber] = useState("");
  const [medicalHistoryNumber, setMedicalHistoryNumber] = useState("");
  const [dose, setDose] = useState("");
  const [documents, setDocuments] = useState(true);
  const currentPatient = decodeHashData("selectedPatient");
  const [emrId, setEMRId] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  // const [cleared, setCleared] = useState(false);
  const [symptomOptions, setSymptomOptions] = useState("");
  const [medicalHistoryOptions, setMedicalHistoryOptions] = useState("");
  const [diagnosisOptions, setDiagnosisOptions] = useState("");
  const [examinationFindingOptions, setExaminationFindingOptions] =
    useState("");
  const [medicationOptions, setMedicationOptions] = useState("");
  const [labInvestigationOptions, setLabInvestigationOptions] = useState("");
  const [notifyModal, setNotifyModal] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState("error");
  const userRole = decodeHashData("userRole");
  const [documentBytes, setDocumentBytes] = useState("");
  const [invoiceDocumentBytes, setInvoiceDocumentBytes] = useState("");
  const [generateInvoice, setGenerateInvoice] = useState(false);
  const [treatmentPlan, setTreatmentPlan] = useState([]);
  const [responseStructure, setResponseStructure] = useState("SOAP");
  const [formValues, setFormValues] = useState({
    pulseRate: "",
    oxygenSaturation: "",
    bloodPressure: "",
    respiratoryRate: "",
    bodyTemp: "",
    bodyHeight: "",
    bodyWeight: "",
    bodyMass: "",
    systolicBP: "",
    diastolicaBP: "",
    heartRate: "",
    headCircumference: "",
    noOfSteps: "",
    sleepDuration: "",
    caloriesBurned: "",
    bloodGluscoseLeval: "",
    fluidIntake: "",
    calorieIntake: "",
    tobaccoChewingFrequency: "",
    alcoholConsumptionFrequency: "",
    smoking: "",
    ageAtMenarche: "",
    ageAtMenopause: "",
    lastMenstrualPeriod: "",
  });
  const [followUp, setFollowUp] = useState("");
  const [pmrDialogOpen, setPmrDialogOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [invoicePdfUrl, setInvoicePdfUrl] = useState(null);
  const [emrData, setEmrData] = useState({});
  const [templateRes, setTemplateRes] = useState([]);
  const [searchTemplate, setSearchTemplate] = useState({});
  const [isTemplateData, setIsTemplateData] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [pmrSummary, setPmrSummary] = useState("");
  const [PMRSummarisedNotes, setPMRSummarisedNotes] = useState([]);
  const [docDetails, setDocDetails] = useState([]);
  const [isTypeAudio, setIsTypeAudio] = useState(false);
  const [selectedDocType, setSelectedDocType] = useState("prescription");
  const [doctorId, setDoctorId] = useState("");
  const user_id = decodeHashData("user_id");
  const currentHospitalHipId = decodeHashData("selectedHospital")?.hip_id;
  const dispatch = useDispatch();
  const emrValueRef = useRef();
  const resetEMRFormRef = useRef();
  const [
    openGenerateInvoiceConsentDialog,
    setOpenGenerateInvoiceConsentDialog,
  ] = useState(false);

  const handleClickOpen = () => {
    setOpenGenerateInvoiceConsentDialog(true);
  };

  useEffect(() => {
    const payload = {
      user_id: user_id,
    };
    dispatch(getAllTemplates(payload)).then((res) => {
      setTemplateRes(res?.payload);
    });
  }, [user_id]);

  const handleSearchTemplate = (value) => {
    const selectedTemplate = templateRes.find(
      (template) => template.template_name === value
    );
    const payload = {
      template_id: selectedTemplate?.id,
    };
    dispatch(getTemplateResponse(payload)).then((res) => {
      setIsTypeAudio(false);
      setSearchTemplate(res?.payload?.template_json);
      setIsTemplateData(true);
    });
  };

  const handlePmrDialogClose = () => {
    setPmrDialogOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    setShowLoader(true);
    if (
      (encounterDetail && Object.keys(encounterDetail).length) ||
      (currentPatient && Object.keys(currentPatient).length)
    ) {
      setDoctorId(
        pageSelected === "1"
          ? encounterDetail?.doc_id || currentPatient.doc_id
          : currentPatient.doc_id || encounterDetail?.doc_id
      );
      const emrPayload = {
        patient_id:
          pageSelected === "1"
            ? encounterDetail?.patientId || currentPatient.patientId
            : currentPatient.patientId || encounterDetail?.patientId,
        doc_id:
          pageSelected === "1"
            ? encounterDetail?.doc_id || currentPatient.doc_id
            : currentPatient.doc_id || encounterDetail?.doc_id,
        appointment_id:
          pageSelected === "1"
            ? encounterDetail?.id || currentPatient.id
            : currentPatient.id || encounterDetail?.id,
        hip_id:
          pageSelected === "1"
            ? encounterDetail?.hip_id || currentPatient.hip_id
            : currentPatient.hip_id || encounterDetail?.hip_id,
        consultation_status: "InProgress",
      };
      dispatch(getEMRId(emrPayload)).then((res) => {
        if (res?.payload) {
          setEmrData(res?.payload);
          setEMRId(res?.payload?.pmr_details?.id);
          setDocDetails(res?.payload?.document_details);
          res?.payload?.document_details?.map((data) => {
            if (
              data.document_type === "OPConsultation" ||
              "OPConsultation_digital"
            )
              setDocumentId(data?.document_id);
          });
          encodeHashData("pmrID", res?.payload?.pmr_details.id);

          encodeHashData("ID", res?.payload?.appointment_details?.patient_id);
          setSearchTemplate(res?.payload?.pmr_details?.pmr_data);
          // const PMRSummarisedNotes = res?.payload?.pmr_details.summarised_notes;
          setIsTypeAudio(false);
          setIsTemplateData(false);
          if (res?.payload?.pmr_details?.summarised_notes?.type === "PMR") {
            setPmrSummary(res?.payload?.pmr_details?.summarised_notes?.summary);
            setSearchTemplate(res?.payload?.pmr_details?.summarised_notes);
          } else if (
            res?.payload?.pmr_details?.summarised_notes?.type === "SOAP"
          ) {
            setPMRSummarisedNotes(res?.payload?.pmr_details?.summarised_notes);
          }

          if (res?.payload?.pmr_details?.advices) {
            setAdvices(res?.payload?.pmr_details?.advices?.split(" | "));
          }
          if (res?.payload?.pmr_details?.notes) {
            setPrescriptionComment(
              res?.payload?.pmr_details?.notes?.split(" | ")
            );
          }
          // if(res?.payload?.pmr_details?.treatment_plan)
          setFollowUp(res?.payload?.appointment_details?.followup_date);
          // setPmrFollowUpDate(res?.payload?.appointment_details?.followup_date);

          setShowLoader(false);
        } else {
          setShowLoader(false);
          return;
        }
      });
    }
  }, []);

  // const createPdfBlob = async (patientDetails) => {
  //   const pdfBlob = await pdf(
  //     <PMRPdf patientData={patientDetails || patientData} />
  //   ).toBlob();

  //   const pdfFile = new File([pdfBlob], "patient_record.pdf", {
  //     type: "application/pdf",
  //   });

  //   return pdfFile;
  // };
  const handleNotifyModalClose = () => {
    setNotifyModal(false);
  };

  const postPMR = async () => {
    setShowLoader(true);
    const currentHospital = decodeHashData("selectedHospital");
    const pmr_request = pdfData;
    pmr_request["pmr_id"] = emrId;
    pmr_request["advice"] = advices
      ? advices.join(" | ").replace(/\n/g, " | ")
      : "";
    pmr_request["notes"] = prescriptionComment
      ? prescriptionComment.join(" | ").replace(/\n/g, " | ")
      : "";
    pmr_request["treatment_plan"] = treatmentPlan
      ? treatmentPlan.join(" | ").replace(/\n/g, " | ")
      : "";
    const pdfPayload = {
      mode: "digital",
      pmr_id: emrId,
    };

    let appointment_request;
    if (followUp) {
      appointment_request = {
        appointment_id: emrData?.appointment_details?.id,
        followup_date: convertDateFormat(followUp, "yyyy-MM-dd"), //convertDateFormat(followUp, "yyyy-MM-dd"),
        consultation_status: "Completed",
      };
    } else {
      appointment_request = {
        appointment_id: emrData?.appointment_details?.id,
        consultation_status: "Completed",
      };
    }
    // const summarised_notes = getSummarisedData();
    const allData = {
      pmr_request,
      appointment_request,
    };
    const finalPayload = {
      docId:
        pageSelected === "1"
          ? encounterDetail?.doc_id || currentPatient.doc_id
          : currentPatient.doc_id || encounterDetail?.doc_id,
      allData: allData,
      generate_invoice: generateInvoice,
    };
    // const blob = await createPdfBlob();
    // dispatch(submitPdf({ blob, pdfPayload }))
    //   .then((pdfResponse) => {
    dispatch(postEMR(finalPayload))
      .then((res) => {
        setShowLoader(false);
        if (res?.meta?.requestStatus === "rejected") {
          setPmrDialogOpen(true);
        } else {
          setNotifyModal(true);
        }
        if (
          !(
            (currentPatient?.patient_details?.abha_number ||
              currentPatient?.abha_number) &&
            (currentPatient?.patient_details?.abha_number ||
              currentPatient?.abha_number) !== ""
          )
        ) {
          if (
            currentHospital?.hip_id === "123123" &&
            currentPatient?.mobile_number
          ) {
            const deepLinkPayload = {
              mobile_no: currentPatient?.mobile_number,
              hip_id: "123123",
              hip_name: currentHospital?.name,
            };
            dispatch(deepLink(deepLinkPayload)).catch((error) =>
              console.log("DeepLink Error:", error)
            );
          }
        }
      })
      .catch((error) => {
        console.log("PostEMR Error:", error);
        setShowLoader(false);
      });
  };
  const filterVitals = (vitalsArr) => {
    const filteredvital = [];
    vitalsArr?.map((item) => {
      const filteredEntry = {};
      for (const key in item) {
        if (item[key]?.length > 0) {
          filteredEntry[key] = item[key];
        }
      }
      filteredvital?.push(filteredEntry);
    });
    return filteredvital;
  };

  const submitEMR = async (invoiceConsent) => {
    setShowLoader(true);
    const currentHospital = decodeHashData("selectedHospital");
    let patientDetails = {};
    if (currentHospital) {
      // const currentPatient = JSON.parse(patient);
      patientDetails = {
        hospitalName: currentHospital?.name || "-",
        patientName:
          currentPatient?.patient_details?.name || currentPatient?.name || "-",
        doctorName: emrData?.doctor_details?.doc_name || "-",
        patientEmail:
          currentPatient?.patient_details?.email ||
          currentPatient?.email ||
          "-",
        patientGender:
          currentPatient?.patient_details?.gender ||
          currentPatient?.gender ||
          "-",
        patientNumber:
          currentPatient?.mobileNumber || currentPatient?.mobile_number || "-",
        patientId: currentPatient?.patientId || "-",
        patientAgeInYears:
          currentPatient?.patient_details?.age_in_years ||
          currentPatient?.age_in_years,
        patientAgeInMonths:
          currentPatient?.patient_details?.age_in_months ||
          currentPatient?.age_in_months,
        patientUid: currentPatient?.patientUid || currentPatient?.patient_uid,
      };
    }
    const pdfFormattedData = emrValueRef?.current?.formattedValues();

    if (followUp) {
      pdfFormattedData["followup"] = followUp;
    }
    setPdfData(pdfFormattedData);
    setPatientData(true);

    encodeHashData("patientDetailsPdf", patientDetails);
    encodeHashData("patientEMRDetails", pdfFormattedData);

    setPmrFinished(true);
    // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

    setStep("preview");
    // let pdf_data = await createPdfBlob(patientDetails);
    // const pdfUrls = URL.createObjectURL(pdf_data);
    // setPdfUrl(pdfUrls);
    let appointment_request;
    if (followUp) {
      appointment_request = {
        appointment_id: emrData?.appointment_details?.id,
        followup_date: followUp, //convertDateFormat(followUp, "yyyy-MM-dd"),
        consultation_status: "Completed",
      };
    } else {
      appointment_request = {
        appointment_id: emrData?.appointment_details?.id,
        consultation_status: "Completed",
      };
    }
    console.log(invoiceConsent);
    const payload = {
      pmr_metadata: {
        doctor_name: emrData?.doctor_details?.doc_name,
        patient_name: patientDetails?.patientName,
        hospital_name: patientDetails?.hospitalName,
        patient_uid: patientDetails?.patientUid,
        patient_gender: patientDetails?.patientGender,
        // document_id: documentId,
        patient_age_years: patientDetails?.patientAgeInYears?.toString(),
        patient_age_months: patientDetails?.patientAgeInMonths?.toString(),
        patient_contact_number: patientDetails?.patientNumber,
        patient_email: patientDetails?.patientEmail,
      },

      pmr_request: {
        pmr_id: emrId,
        vital: {
          height: pdfFormattedData?.vital?.height,
          weight: pdfFormattedData?.vital?.weight,
          pulse: pdfFormattedData?.vital?.pulse,
          body_temperature: pdfFormattedData?.vital?.body_temperature,
          oxygen_saturation: pdfFormattedData?.vital?.oxygen_saturation,
          respiratory_rate: pdfFormattedData?.vital?.respiratory_rate,
          body_mass_index: pdfFormattedData?.vital?.body_mass_index,
          systolic_blood_pressure:
            pdfFormattedData?.vital?.systolic_blood_pressure,
          diastolic_blood_pressure:
            pdfFormattedData?.vital?.diastolic_blood_pressure,
          no_of_steps: pdfFormattedData?.vital?.no_of_steps,
          sleep_duration: pdfFormattedData?.vital?.sleep_duration,
          calories_burned: pdfFormattedData?.vital?.calories_burned,
          blood_glucose: pdfFormattedData?.vital?.blood_glucose,
          fluid_intake: pdfFormattedData?.vital?.fluid_intake,
          calories_intake: pdfFormattedData?.vital?.calories_intake,
          tobacco_chewing_freq: pdfFormattedData?.vital?.tobacco_chewing_freq,
          alcohol_consumption_freq:
            pdfFormattedData?.vital?.alcohol_consumption_freq,
          smoking_freq: pdfFormattedData?.vital?.smoking_freq,
          age_at_menarche: pdfFormattedData?.vital?.age_at_menarche,
          age_at_menopause: pdfFormattedData?.vital?.age_at_menopause,
          last_menstrual_period: pdfFormattedData?.vital?.last_menstrual_period,
        },
        examination_findings: pdfFormattedData?.examination_findings,
        diagnosis: pdfFormattedData?.diagnosis,
        symptom: pdfFormattedData?.symptom,
        medication: pdfFormattedData?.medication,
        lab_investigation: pdfFormattedData?.lab_investigation,
        vaccination_record: pdfFormattedData?.vaccination_record,
        medical_history: pdfFormattedData?.medical_history,
        advice: pdfFormattedData?.advice,
        notes: pdfFormattedData?.notes,
        treatment_plan: treatmentPlan
          ? treatmentPlan.join(" | ").replace(/\n/g, " | ")
          : "",
      },
      appointment_request,
      generate_invoice: invoiceConsent,
    };
    docDetails.forEach((item) => {
      if (item?.document_type === "OPConsultation_digital") {
        if (item?.document_id) {
          payload.pmr_metadata.document_id = item.document_id;
        }
      }
    });

    dispatch(previewPMR(payload)).then((response) => {
      if (response?.payload) {
        setDocumentId(response?.payload?.document_id);
        setDocumentBytes(response?.payload?.data);
        setInvoiceDocumentBytes(response?.payload?.invoice_data);
        setShowLoader(false);
        setGenerateInvoice(invoiceConsent);
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        if (response?.payload?.data) {
          const decodedByteCode = atob(response?.payload?.data);
          const byteNumbers = new Array(decodedByteCode.length);
          for (let i = 0; i < decodedByteCode.length; i++) {
            byteNumbers[i] = decodedByteCode.charCodeAt(i);
          }
          const blobData = new Blob([new Uint8Array(byteNumbers)], {
            type: "application/pdf",
          });
          // const pdfUrls = URL.createObjectURL(blobData);
          setPdfUrl(URL.createObjectURL(blobData));
          return () => {
            URL.revokeObjectURL(pdfUrl);
          };
        }
        if (response?.payload?.invoice_data) {
          const decodedByteCode = atob(response?.payload?.invoice_data);
          const byteNumbers = new Array(decodedByteCode.length);
          for (let i = 0; i < decodedByteCode.length; i++) {
            byteNumbers[i] = decodedByteCode.charCodeAt(i);
          }
          const blobData = new Blob([new Uint8Array(byteNumbers)], {
            type: "application/pdf",
          });
          // const pdfUrls = URL.createObjectURL(blobData);
          setInvoicePdfUrl(URL.createObjectURL(blobData));
          return () => {
            URL.revokeObjectURL(invoicePdfUrl);
          };
        }
      } else return;
    });
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const resetEMRForm = () => {
    setFormValues({
      pulseRate: "",
      oxygenSaturation: "",
      respiratoryRate: "",
      bodyTemp: "",
      bodyHeight: "",
      bodyWeight: "",
      bodyMass: "",
      bloodPressure: "",
      systolicBP: "",
      diastolicaBP: "",
      heartRate: "",
      headCircumference: "",
      noOfSteps: "",
      sleepDuration: "",
      caloriesBurned: "",
      bloodGluscoseLeval: "",
      fluidIntake: "",
      calorieIntake: "",
      tobaccoChewingFrequency: "",
      alcoholConsumptionFrequency: "",
      smoking: "",
      ageAtMenarche: "",
      ageAtMenopause: "",
      lastMenstrualPeriod: "",
    });
    setMedicalHistory([]);
    setExistingCondition([]);
    setSymptoms([]);
    setExamFinding([]);
    setDiagnosis([]);
    setMedications([]);
    setLabInvestigation([]);
    setOptionTextValues({});
    setExistingConditionsSpecs({});
    setExaminationSpecs({});
    setSymptomsSpecs({});
    setDiagnosisSpecs({});
    setMedicationsSpecs({});
    setLabInvestigationSpecs({});
    setPrescriptionComment([]);
    setAdvices([]);
    setTreatmentPlan([]);
  };

  const saveEMR = () => {
    setShowLoader(true);
    const pmr_request = emrValueRef?.current?.formattedValues();
    pmr_request["pmr_id"] = emrId;
    // pmr_request["advice"] =
    //   advices.length > 0 ? advices.join(" | ").replace(/\n/g, " | ") : "";
    // pmr_request["notes"] =
    //   prescriptionComment.length > 0
    //     ? prescriptionComment.join(" | ").replace(/\n/g, " | ")
    //     : "";
    // pmr_request["treatment_plan"] = treatmentPlan
    //   ? treatmentPlan.join(" | ").replace(/\n/g, " | ")
    //   : "";

    let appointment_request;

    if (followUp) {
      appointment_request = {
        appointment_id: emrData?.appointment_details?.id,
        followup_date: followUp, //convertDateFormat(followUp, "yyyy-MM-dd"),
        consultation_status: "InProgress",
      };
    } else {
      appointment_request = {
        appointment_id: emrData?.appointment_details?.id,
        consultation_status: "InProgress",
      };
    }
    const allData = {
      pmr_request,
      appointment_request,
    };
    const finalPayload = {
      docId:
        pageSelected === "1"
          ? encounterDetail?.doc_id || currentPatient.doc_id
          : currentPatient.doc_id || encounterDetail?.doc_id,
      allData: allData,
    };
    dispatch(postEMR(finalPayload)).then((res) => {
      if (res?.error && Object.keys(res?.error)?.length > 0) {
        setErrorMessage("Something went wrong while saving the details");
        setShowSnackbar(true);
        setSnackbarStatus("error");
        setShowLoader(false);
        return;
      } else {
        console.log("Details saved successfully");
        setErrorMessage("Details saved successfully");
        setShowSnackbar(true);
        setSnackbarStatus("success");
        setShowLoader(false);
      }
    });
  };

  const editPMR = () => {
    setStep("create");
  };

  const handleDateChange = (event) => {
    setFollowUp(event.target.value);
  };

  const onSnackbarClose = () => {
    setShowSnackbar(false);
  };

  return (
    <>
      <PatientEMRWrapper>
        <CustomSnackbar
          message={errorMessage || "Something went wrong"}
          open={showSnackbar}
          status={snackbarStatus}
          onClose={onSnackbarClose}
        />
        <CustomLoader open={showLoader} />
        <CustomizedDialogs
          open={pmrDialogOpen}
          handleClose={handlePmrDialogClose}
        />
        {step === "create" && (
          <div>
            <PatientDetailsHeader
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              documents={documents}
              templateRes={templateRes}
              handleSearchTemplate={handleSearchTemplate}
            />
            {/* {(userRole === "DOCTOR" || userRole === "ADMIN") && (
            <RecorderComponent
              pmrSummary={pmrSummary}
              summaryContent={PMRSummarisedNotes}
              setSummaryContent={setPMRSummarisedNotes}
              responseStructure={responseStructure}
              setResponseStructure={setResponseStructure}
              clearForm={resetEMRForm}
            />
          )} */}
            <EMRFormWrapper>
              <EMRComponent
                searchTemplate={searchTemplate}
                setSearchTemplate={setSearchTemplate}
                PMRSummarisedNotes={PMRSummarisedNotes}
                setPMRSummarisedNotes={setPMRSummarisedNotes}
                emrValueRef={emrValueRef}
                resetEMRFormRef={resetEMRFormRef}
                isEMR={true}
                isTypeAudio={isTypeAudio}
                setIsTypeAudio={setIsTypeAudio}
                isTemplateData={isTemplateData}
                responseStructure={responseStructure}
                setResponseStructure={setResponseStructure}
                followUp={followUp}
                setFollowUp={setFollowUp}
                handleDateChange={handleDateChange}
                doctorId={doctorId}
              />
              <EMRFooter style={{ position: "sticky", bottom: 0 }}>
                <SecondaryButton
                  onClick={resetEMRFormRef?.current?.resetEMRForm}
                  style={{ padding: "5px 16px" }}
                >
                  Clear
                </SecondaryButton>
                <PrimaryButton onClick={saveEMR}>Save</PrimaryButton>
                {(userRole === "DOCTOR" || userRole === "ADMIN") && (
                  <PrimaryButton
                    onClick={
                      currentHospitalHipId === "123123"
                        ? handleClickOpen
                        : () => submitEMR(false)
                    }
                  >
                    Review Prescription
                  </PrimaryButton>
                )}
              </EMRFooter>
            </EMRFormWrapper>
          </div>
        )}
        {pmrFinished && step === "preview" && (
          <PdfDisplayWrapper>
            {/* <PageTitle>Preview</PageTitle>
          <PageSubText>
            Closely Review the Details Before Confirming
          </PageSubText> */}
            <SendPMR
              notifyModal={notifyModal}
              handleNotifyModalClose={handleNotifyModalClose}
              documentId={documentId}
            />
            {!isMobile && (
              <PDFViewerWrapper style={{ height: height - 100 }}>
                {/* <div style={{ width: "100%", height: "400px" }} zoom={1}> */}
                {/* <PMRPdf 
                  patientData={patientData} 
                 setIsFinishDisabled={setIsFinishDisabled}
                /> */}
                <embed
                  style={{ width: "100%", height: height - 160 }}
                  src={`data:application/pdf;base64,${
                    selectedDocType === "invoice"
                      ? invoiceDocumentBytes
                      : documentBytes
                  }`}
                />
                {/* </div> */}
                <PDFButtonWrapper>
                  {!(
                    invoiceDocumentBytes === null ||
                    invoiceDocumentBytes === "none" ||
                    invoiceDocumentBytes === "" ||
                    invoiceDocumentBytes === undefined
                  ) && (
                    <Select
                      defaultValue="prescription"
                      size="small"
                      sx={{
                        minWidth: 120,
                        marginRight: 1,
                        backgroundColor: "white",
                      }}
                      onChange={(e) => setSelectedDocType(e.target.value)}
                    >
                      <MenuItem value="prescription">Prescription</MenuItem>
                      <MenuItem value="invoice">Invoice</MenuItem>
                    </Select>
                  )}
                  <SecondaryButton
                    onClick={editPMR}
                    style={{ padding: "8px 16px" }}
                  >
                    Edit
                  </SecondaryButton>
                  <PrimaryButton onClick={postPMR}>
                    Finish Prescription
                  </PrimaryButton>
                </PDFButtonWrapper>
              </PDFViewerWrapper>
            )}
            {isMobile && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "8px",
                    marginBottom: "10px",
                  }}
                >
                  {!(
                    invoiceDocumentBytes === null ||
                    invoiceDocumentBytes === "none" ||
                    invoiceDocumentBytes === "" ||
                    invoiceDocumentBytes === undefined
                  ) && (
                    <Select
                      defaultValue="prescription"
                      size="small"
                      fullWidth
                      sx={{
                        backgroundColor: "white",
                      }}
                      onChange={(e) => setSelectedDocType(e.target.value)}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      }}
                    >
                      <MenuItem value="prescription">Prescription</MenuItem>
                      <MenuItem value="invoice">Invoice</MenuItem>
                    </Select>
                  )}
                  <MobilePDFButtonWrapper>
                    <SecondaryButton style={{ width: "50%" }} onClick={editPMR}>
                      Edit
                    </SecondaryButton>
                    <PrimaryButton style={{ width: "50%" }} onClick={postPMR}>
                      Finish Prescription
                    </PrimaryButton>
                  </MobilePDFButtonWrapper>
                </div>
                <PDFViewerWrapper>
                  {/* <PDFViewer> */}
                  {selectedDocType !== "invoice" ? (
                    <Document
                      file={pdfUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.apply(null, Array(numPages))
                        .map((x, i) => i + 1)
                        .map((page) => (
                          <Page
                            wrap
                            pageNumber={page}
                            renderTextLayer={false}
                            width={width}
                            height="auto"
                          />
                        ))}
                    </Document>
                  ) : (
                    <Document
                      file={invoicePdfUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.apply(null, Array(numPages))
                        .map((x, i) => i + 1)
                        .map((page) => (
                          <Page
                            wrap
                            pageNumber={page}
                            renderTextLayer={false}
                            width={width}
                            height="auto"
                          />
                        ))}
                    </Document>
                  )}
                  {/* </PDFViewer> */}
                </PDFViewerWrapper>
              </>
            )}
          </PdfDisplayWrapper>
        )}
      </PatientEMRWrapper>
      <GenerateInvoiceDialog
        open={openGenerateInvoiceConsentDialog}
        setOpen={setOpenGenerateInvoiceConsentDialog}
        handlePreviewPrescription={submitEMR}
      />
    </>
  );
};

export default PatientEMRDetails;
