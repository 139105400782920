import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect } from "react";
import OtpInput from "../OTPValidation";

const PhoneVerificationWrapper = styled("div")(({ theme }) => ({
  ".validate-phone-form": {
    display: "flex",
    alignItems: "center",
    marginBottom: "4px",
    gap: "24px",
  },
  ".verification-btn": {
    "&.MuiButtonBase-root": {
      "&": theme.typography.primaryButton,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  },
  ".phone-text": {
    "&.MuiFormControl-root": {
      "& > .MuiInputBase-root": {
        display: "flex",
        height: "48px",
        width: "320px",
        justifyContent: "center",
        alignItems: "center",
        flex: "1 0 0",
        alignSelf: "stretch",
        [theme.breakpoints.down("sm")]: {
          width: "auto",
        },
      },
    },
  },
  ".otp-title": {
    "&.MuiTypography-root": {
      fontFamily: "Inter",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "160%",
      marginBottom: "4px",
    },
  },
}));
const PhoneVerification = ({
  number,
  handleNumberChange,
  isMobileError,
  handleSubmit,
  PhoneDisabled,
  setSixDigitOTP,
  verifyOTP,
  phoneNumberUsed,
  seconds,
  selectedAbhaModeOption,
  isInputValid,
  handleCreateAbhaFlow,
  createAbhaBtn,
  clickMobileOTPcount,
  handleFetchAbha,
  abhaDetails,
  handleGetIndexChange,
  abhaIndex,
}) => {
  useEffect(() => {
    console.log(abhaIndex);
  }, [abhaDetails]);
  return (
    <PhoneVerificationWrapper>
      {!isInputValid && (
        <>
          <InputLabel>Enter Mobile Number</InputLabel>
          <div className="validate-phone-form">
            <TextField
              type="tel"
              value={number}
              onChange={handleNumberChange}
              error={isMobileError}
              className="phone-text"
              disabled={isInputValid}
            />
            <Button
              variant="contained"
              onClick={handleFetchAbha}
              disabled={number?.length < 10}
            >
              Fetch
            </Button>

            {createAbhaBtn && (
              <Button
                variant="contained"
                hidden={createAbhaBtn}
                onClick={handleCreateAbhaFlow}
              >
                Create ABHA
              </Button>
            )}
          </div>
          <Stack
            flexDirection={"row"}
            gap={2}
            flexWrap="wrap"
            useFlexGap
            my={2}
          >
            {abhaDetails?.ABHA?.length > 0 &&
              abhaDetails?.ABHA?.map((item, index) => {
                return (
                  <Stack
                    key={index}
                    sx={{
                      padding: 2.5,
                      borderRadius: "8px",
                      border: "1px solid #E0E0E0",
                      width: {
                        xs: "100%",
                        sm: "calc(50% - 16px)",
                        md: "calc(33.33% - 16px)",
                      },
                      transition: "all 0.3s ease",
                      "&:hover": {
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        borderColor: "primary.main",
                      },
                      backgroundColor: "background.paper",
                    }}
                  >
                    <FormControl>
                      <RadioGroup
                        value={abhaIndex}
                        onChange={handleGetIndexChange}
                      >
                        <FormControlLabel
                          value={item?.index}
                          control={<Radio size="small" />}
                          label={
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: 500 }}
                            >
                              {`ABHA Number: ${item?.ABHANumber || ""}`}
                            </Typography>
                          }
                          sx={{
                            mb: 1,
                            "& .MuiFormControlLabel-label": {
                              fontSize: "0.9rem",
                            },
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1 }}
                    >
                      {`${item?.name} | ${item?.gender}`}
                    </Typography>
                  </Stack>
                );
              })}
          </Stack>
          {selectedAbhaModeOption !== "create_abha" &&
            abhaDetails?.ABHA?.length > 0 &&
            (seconds === -1 ? (
              <Button
                disabled={PhoneDisabled || !abhaIndex}
                onClick={() => handleSubmit("phone_number")}
                variant="contained"
                className="verification-btn"
              >
                Verify
              </Button>
            ) : (
              <Button
                disabled={PhoneDisabled || seconds > 0}
                style={{
                  color: seconds > 0 ? "#DFE3E8" : "#FFF",
                }}
                variant="contained"
                className="verification-btn"
                onClick={() => handleSubmit("phone_number")}
              >
                Resend OTP
              </Button>
            ))}
          <div>
            <span style={{ color: "red" }}>
              {isMobileError ? "Please enter valid number" : ""}
            </span>
          </div>
          {selectedAbhaModeOption !== "create_abha" &&
            clickMobileOTPcount < 3 &&
            (seconds < 0 ? null : (
              <h5>
                Resend OTP in: 00:
                {seconds < 10 ? `0${seconds}` : seconds}
              </h5>
            ))}
          {!phoneNumberUsed && (
            <div>
              <Typography className="otp-title">Enter OTP</Typography>
              <OtpInput
                setSixDigitOTP={setSixDigitOTP}
                verifyOTP={verifyOTP}
                type="phone_number"
              />
            </div>
          )}
        </>
      )}
    </PhoneVerificationWrapper>
  );
};

export default PhoneVerification;
