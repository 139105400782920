import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Autocomplete,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  createAbhaAddress,
  registerAbhaPatient,
  registerPatient,
} from "../../pages/PatientRegistration/PatientRegistration.slice";
import { AppointmentPageActions } from "../../pages/AppointmentPage/AppointmentPage.slice";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../CustomSnackbar";
import { validateAbhaAddress } from "../../utils/utils";
import { format } from "date-fns";
import { decodeHashData, encodeHashData } from "../../utils/hashing";

const AadhaarPatientRegForm = ({
  setUserCreated,
  txnId,
  patientAbhaData,
  abhaSuggestionList,
  abhaSuggestionTxnId,
  selectedAbhaModeOption,
  patientAbhaToken,
  abhaNewMobile,
  isCompare,
  comparedAbhaPatientData,
}) => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    middlename: "",
    abhaNumber: "",
    abhaAddress: "",
    email: "",
    mobile: "",
    dob: "",
    gender: "M",
    address: "",
    pincode: "",
  });
  const currentHospital = decodeHashData("selectedHospital");
  const dispatch = useDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const navigate = useNavigate();
  const [abhaAddressError, setAbhaAddressError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [abhaAddressInputValue, setAbhaAddressInputValue] = useState("");
  // let combinedAbhaSuggestions = (abhaSuggestionList).concat(patientAbhaData?.phrAddress || []);
  const [selectedDataSource, setSelectedDataSource] = useState("current"); // State for selected data source
  const [registeredChecked, setRegisteredChecked] = useState(false);
  const [abdmChecked, setAbdmChecked] = useState(true);

  let abhaSuggestions = patientAbhaData?.phrAddress || [];
  const [abhaAddressValue, setAbhaAddressValue] = useState(
    abhaSuggestions[0] || ""
  );
  const [isNewAbha, setIsNewAbha] = useState(false);
  const [patientAddress, setPatientAddress] = useState(
    patientAbhaData?.address?.line
      ? patientAbhaData?.address?.line !== null
        ? patientAbhaData?.address?.line +
        " " +
        patientAbhaData?.address?.district +
        " " +
        patientAbhaData?.address?.state
        : patientAbhaData?.address
      : " "
  );
  const [patientPincode, setPatientPincode] = useState(
    patientAbhaData?.pinCode ||
    patientAbhaData?.pincode ||
    patientAbhaData?.address?.pincode ||
    ""
  );

  const [currentAddress, setCurrentAddress] = useState("");
  const [currentPincode, setCurrentPincode] = useState("");
  const [comparedAddress, setComparedAddress] = useState("");
  const [comparedPincode, setComparedPincode] = useState("");

  useEffect(() => {
    setCurrentAddress(patientAbhaData?.address || "");
    setCurrentPincode(
      patientAbhaData?.pinCode || patientAbhaData?.pincode || ""
    );
  }, [patientAbhaData]);

  useEffect(() => {
    setComparedAddress(comparedAbhaPatientData?.address || "");
    setComparedPincode(
      comparedAbhaPatientData?.pinCode || comparedAbhaPatientData?.pincode || ""
    );
  }, [comparedAbhaPatientData]);

  const handleCurrentAddressChange = (event) => {
    setCurrentAddress(event.target.value);
  };

  const handleCurrentPincodeChange = (event) => {
    setCurrentPincode(event.target.value);
  };

  const handleComparedAddressChange = (event) => {
    setComparedAddress(event.target.value);
  };

  const handleComparedPincodeChange = (event) => {
    setComparedPincode(event.target.value);
  };

  const handleNewAbhaChange = (event) => {
    setIsNewAbha(event.target.checked);
    setAbhaAddressValue("");
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "address") {
      setPatientAddress(value);
    } else if (name === "pincode") {
      setPatientPincode(value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "abhaAddress") {
      if (!validateAbhaAddress(value)) {
        setAbhaAddressError(true);
      } else {
        setAbhaAddressError(false);
      }
    }
  };

  const abhaAddressInputChange = (event, newInputValue) => {
    setAbhaAddressInputValue(newInputValue);
    const abhaNumber =
      patientAbhaData?.ABHANumber ||
      patientAbhaData?.abha_number ||
      patientAbhaData?.abhaNumber;
    let abhaNumberWithoutHyphens;
    if (abhaNumber) abhaNumberWithoutHyphens = abhaNumber.replace(/-/g, "");
    if (
      (abhaNumber && abhaNumber === newInputValue) ||
      abhaNumberWithoutHyphens === newInputValue
    ) {
      setAbhaAddressError(false);
      setAbhaAddressValue(newInputValue);
    } else {
      if (!validateAbhaAddress(newInputValue)) {
        setAbhaAddressError(true);
      } else {
        setAbhaAddressError(false);
        setAbhaAddressValue(newInputValue);
      }
    }
  };

  const handleExsistingAbha = (event, newValue) => {
    if (validateAbhaAddress(newValue)) setAbhaAddressValue(newValue);
  };

  const handleExsistingAbhaInput = (event, newValue) => {
    if (validateAbhaAddress(newValue)) setAbhaAddressValue(newValue);
  };

  const formatDob = (date) => {
    return format(new Date(date), "yyyy-MM-dd");
  };

  const handleAbdmChange = (event) => {
    setAbdmChecked(event.target.checked);
    if (event.target.checked) {
      setRegisteredChecked(false); // Uncheck the other checkbox
    }
  };

  const handleRegisteredChange = (event) => {
    setRegisteredChecked(event.target.checked);
    if (event.target.checked) {
      setAbdmChecked(false); // Uncheck the other checkbox
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    function ensureSbxSuffix(value) {
      if (typeof value !== "string") return value; // Ensure the value is a string
      if (!value.endsWith("@sbx")) {
        return value + "@sbx";
      }
      return value;
    }

    if (currentHospital) {
      if (Object.keys(patientAbhaData).length > 0) {
        const abha_address =
          patientAbhaData?.preferredAbhaAddress ||
          patientAbhaData?.abha_address ||
          patientAbhaData?.abhaAddress ||
          abhaAddressValue;
        const primary_abha_address =
          patientAbhaData?.preferredAbhaAddress ||
          patientAbhaData?.abha_address ||
          patientAbhaData?.abhaAddress ||
          abhaAddressValue;
        const payload = {
          name:
            patientAbhaData?.name ||
            patientAbhaData?.firstName +
            " " +
            patientAbhaData?.middleName +
            " " +
            patientAbhaData?.lastName,
          email: patientAbhaData?.email,
          mobile_number:
            abhaNewMobile ||
            patientAbhaData?.mobile ||
            patientAbhaData?.mobile_number ||
            patientAbhaData?.identifiers[0].value,
          abha_address: ensureSbxSuffix(abha_address),
          primary_abha_address: ensureSbxSuffix(primary_abha_address),
          DOB: patientAbhaData?.DOB
            ? format(new Date(patientAbhaData?.DOB), "dd-MM-yyyy")
            : "" ||
            patientAbhaData?.dob ||
            `${patientAbhaData?.dayOfBirth}-${patientAbhaData?.monthOfBirth}-${patientAbhaData?.yearOfBirth}`,
          gender: patientAbhaData?.gender,
          abha_number:
            patientAbhaData?.ABHANumber ||
            patientAbhaData?.abha_number ||
            patientAbhaData?.abhaNumber ||
            patientAbhaData?.identifiers?.[1].value ||
            "",
          pincode:
            patientPincode ||
            patientAbhaData?.pinCode ||
            patientAbhaData?.pincode ||
            "",
          //address: patientAbhaData?.address?.line !== null ? patientAbhaData?.address?.line + " " + patientAbhaData?.address?.district + " " + patientAbhaData?.address?.state : patientAbhaData?.address?.district + " " + patientAbhaData?.address?.state || patientAbhaData?.address,
          address: patientAbhaData?.address || patientAddress,
          state_name:
            patientAbhaData?.stateName || patientAbhaData?.address?.state || "",
          district_name:
            patientAbhaData?.districtName ||
            patientAbhaData?.address?.district ||
            "",
          district_code: patientAbhaData?.districtCode || "",
          hip_id: currentHospital?.hip_id,
          profile_photo: patientAbhaData?.photo,
        };
        const abhaAddressPayload = {
          abhaAddress:
            patientAbhaData?.preferredAbhaAddress ||
            abhaAddressValue ||
            formData?.abhaAddress,
          txnId: abhaSuggestionTxnId,
        };
        if (isNewAbha) {
          dispatch(createAbhaAddress(abhaAddressPayload)).then((result) => {
            if (result?.error && Object.keys(result?.error)?.length > 0) {
              setShowSnackbar(true);
              return;
            }
            if (result?.payload?.error?.code === "ABDM-1101") {
              setErrorMessage(result?.payload?.error?.message);
              setShowSnackbar(true);
              return;
            } else {
              console.log("createAbhaAddress");
              dispatch(registerAbhaPatient(payload)).then((res) => {
                if (res?.error && Object.keys(res?.error)?.length > 0) {
                  setShowSnackbar(true);
                  return;
                }
                const patientData = res?.payload;
                encodeHashData("selectedPatient", patientData);
                let userDetails;
                if (patientAbhaToken) {
                  userDetails = {
                    ...patientData,
                    ...{
                      id: res?.payload?.id,
                      token: patientAbhaToken,
                    },
                  };
                } else {
                  userDetails = {
                    ...patientData,
                    ...{
                      id: res?.payload?.id,
                      abhaBytes: patientAbhaData?.abha_card_bytes,
                    },
                  };
                }
                setUserCreated(true);
                dispatch(
                  AppointmentPageActions.setSelectedPatientData(userDetails)
                );
                navigate("/registered-patient");
              });
            }
          });
        } else {
          dispatch(registerAbhaPatient(payload)).then((res) => {
            if (res?.error && Object.keys(res?.error)?.length > 0) {
              setShowSnackbar(true);
              return;
            }
            const patientData = res?.payload;
            encodeHashData("selectedPatient", patientData);
            let userDetails;
            if (patientAbhaToken) {
              userDetails = {
                ...patientData,
                ...{
                  id: res?.payload?.id,
                  token: patientAbhaToken,
                },
              };
            } else {
              userDetails = {
                ...patientData,
                ...{
                  id: res?.payload?.id,
                  abhaBytes: patientAbhaData?.abha_card_bytes,
                },
              };
            }
            setUserCreated(true);
            dispatch(
              AppointmentPageActions.setSelectedPatientData(userDetails)
            );
            navigate("/registered-patient");
          });
        }
      }
    }
  };

  const handleComparedSubmit = (event) => {
    event.preventDefault();
    function ensureSbxSuffix(value) {
      if (typeof value !== "string") return value; // Ensure the value is a string
      if (!value.endsWith("@sbx")) {
        return value + "@sbx";
      }
      return value;
    }
    const dataSource = registeredChecked
      ? comparedAbhaPatientData
      : patientAbhaData;

    if (currentHospital) {
      if (Object.keys(dataSource).length > 0) {
        const abha_address =
          dataSource?.preferredAbhaAddress ||
          dataSource?.abha_address ||
          dataSource?.abhaAddress ||
          abhaAddressValue;
        const primary_abha_address =
          dataSource?.preferredAbhaAddress ||
          dataSource?.abha_address ||
          dataSource?.abhaAddress ||
          abhaAddressValue;
        const payload = {
          name:
            dataSource?.name ||
            dataSource?.firstName +
            " " +
            dataSource?.middleName +
            " " +
            dataSource?.lastName,
          email: dataSource?.email,
          mobile_number:
            abhaNewMobile ||
            dataSource?.mobile ||
            dataSource?.mobile_number ||
            dataSource?.identifiers[0].value,
          abha_address: ensureSbxSuffix(abha_address),
          primary_abha_address: ensureSbxSuffix(primary_abha_address),
          DOB: dataSource?.DOB
            ? format(new Date(dataSource?.DOB), "dd-MM-yyyy")
            : "" ||
            dataSource?.dob ||
            `${dataSource?.dayOfBirth}-${dataSource?.monthOfBirth}-${dataSource?.yearOfBirth}`,
          gender: dataSource?.gender,
          abha_number:
            dataSource?.ABHANumber ||
            dataSource?.abha_number ||
            dataSource?.abhaNumber ||
            dataSource?.identifiers?.[1].value ||
            "",
          pincode: registeredChecked
            ? comparedPincode
            : currentPincode || dataSource?.pinCode || dataSource?.pincode,

          //address: dataSource?.address?.line !== null ? dataSource?.address?.line + " " + dataSource?.address?.district + " " + dataSource?.address?.state : dataSource?.address?.district + " " + dataSource?.address?.state || dataSource?.address,
          address: registeredChecked
            ? comparedAddress
            : currentAddress || dataSource?.address || patientAddress,
          state_name: dataSource?.stateName || dataSource?.address?.state || "",
          district_name:
            dataSource?.districtName || dataSource?.address?.district || "",
          district_code: dataSource?.districtCode || "",
          hip_id: currentHospital?.hip_id,
          "profile_photo": dataSource?.photo ||
            dataSource?.profile_photo
        };
        const abhaAddressPayload = {
          abhaAddress:
            dataSource?.preferredAbhaAddress ||
            abhaAddressValue ||
            formData?.abhaAddress,
          txnId: abhaSuggestionTxnId,
        };
        if (isNewAbha) {
          dispatch(createAbhaAddress(abhaAddressPayload)).then((result) => {
            if (result?.error && Object.keys(result?.error)?.length > 0) {
              setShowSnackbar(true);
              return;
            }
            if (result?.payload?.error?.code === "ABDM-1101") {
              setErrorMessage(result?.payload?.error?.message);
              setShowSnackbar(true);
              return;
            } else {
              console.log("createAbhaAddress");
              dispatch(registerAbhaPatient(payload)).then((res) => {
                if (res?.error && Object.keys(res?.error)?.length > 0) {
                  setShowSnackbar(true);
                  return;
                }
                const patientData = res?.payload;
                encodeHashData("selectedPatient", patientData);
                let userDetails;
                if (patientAbhaToken) {
                  userDetails = {
                    ...patientData,
                    ...{
                      id: res?.payload?.id,
                      token: patientAbhaToken,
                    },
                  };
                } else {
                  userDetails = {
                    ...patientData,
                    ...{
                      id: res?.payload?.id,
                      abhaBytes: dataSource?.abha_card_bytes,
                    },
                  };
                }
                setUserCreated(true);
                dispatch(
                  AppointmentPageActions.setSelectedPatientData(userDetails)
                );
                navigate("/registered-patient");
              });
            }
          });
        } else {
          dispatch(registerAbhaPatient(payload)).then((res) => {
            if (res?.error && Object.keys(res?.error)?.length > 0) {
              setShowSnackbar(true);
              return;
            }
            const patientData = res?.payload;
            encodeHashData("selectedPatient", patientData);
            let userDetails;
            if (patientAbhaToken) {
              userDetails = {
                ...patientData,
                ...{
                  id: res?.payload?.id,
                  token: patientAbhaToken,
                },
              };
            } else {
              userDetails = {
                ...patientData,
                ...{
                  id: res?.payload?.id,
                  abhaBytes: patientAbhaData?.abha_card_bytes,
                },
              };
            }
            setUserCreated(true);
            dispatch(
              AppointmentPageActions.setSelectedPatientData(userDetails)
            );
            navigate("/registered-patient");
          });
        }
      }
    }
  };

  const onSnackbarClose = () => {
    setShowSnackbar(false);
  };
  return (
    Object.keys(patientAbhaData).length > 0 && (
      <>
        <CustomSnackbar
          message={errorMessage || "Something went wrong"}
          open={showSnackbar}
          status={"error"}
          onClose={onSnackbarClose}
        />
        {!isCompare ? (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <TextField
                  label="First Name"
                  name="firstname"
                  value={
                    patientAbhaData?.firstName ||
                    patientAbhaData?.name?.split(" ")[0]
                  }
                  onChange={handleChange}
                  disabled
                  required
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  label="Middle Name"
                  name="middlename"
                  value={
                    patientAbhaData?.middleName ||
                    patientAbhaData?.name?.split(" ")[1]
                  }
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  label="Last Name"
                  name="lastname"
                  value={
                    patientAbhaData?.lastName ||
                    patientAbhaData?.name?.split(" ")[2]
                  }
                  onChange={handleChange}
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  label="Email Address"
                  name="email"
                  value={patientAbhaData?.email}
                  onChange={handleChange}
                  type="email"
                  // required
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  label="Mobile Number"
                  name="mobile"
                  value={
                    abhaNewMobile ||
                    patientAbhaData?.mobile ||
                    patientAbhaData?.mobile_number
                  } //  || patientAbhaData?.identifiers[0]?.value
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  label="DOB"
                  name="dob"
                  value={
                    patientAbhaData?.dob ||
                    patientAbhaData?.DOB ||
                    `${patientAbhaData?.dayOfBirth}-${patientAbhaData?.monthOfBirth}-${patientAbhaData?.yearOfBirth}`
                  }
                  onChange={handleChange}
                  disabled
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  label="Gender"
                  name="gender"
                  value={patientAbhaData?.gender}
                  onChange={handleChange}
                  disabled
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  label="ABHA number"
                  name="abhaNumber"
                  value={
                    patientAbhaData?.ABHANumber ||
                    patientAbhaData?.abha_number ||
                    patientAbhaData?.abhaNumber
                  } // || patientAbhaData?.identifiers[1]?.value
                  onChange={handleChange}
                  disabled
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              {selectedAbhaModeOption === "create_abha" && (
                <Grid item xs={12} md={5}>
                  <FormControlLabel
                    checked={isNewAbha}
                    onChange={handleNewAbhaChange}
                    control={<Checkbox />}
                    label="Create New ABHA Address"
                  />
                </Grid>
              )}
              {selectedAbhaModeOption === "create_abha" && isNewAbha && (
                <Grid item xs={12} md={5}>
                  <Autocomplete
                    freeSolo
                    name="abhaAddress"
                    id="abhaAddress"
                    // error={abhaAddressError}
                    value={abhaAddressValue}
                    options={abhaSuggestionList}
                    // onChange={(event, newValue) => {
                    //   setAbhaAddressValue(newValue);
                    // }}
                    // inputValue={abhaAddressInputValue}
                    onInputChange={abhaAddressInputChange}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Abha address"
                        error={abhaAddressError}
                        helperText={
                          abhaAddressError
                            ? "Your ABHA Address must be 8-18 characters long, alphanumeric, and can include special which cannot be at the beginning or end of the address"
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
              )}
              {!isNewAbha && selectedAbhaModeOption === "create_abha" && (
                <Grid item xs={12} md={5}>
                  <Autocomplete
                    freeSolo
                    name="abhaAddress"
                    id="abhaAddress"
                    value={abhaAddressValue}
                    options={abhaSuggestions}
                    onChange={handleExsistingAbha}
                    onInputChange={handleExsistingAbhaInput}
                    disabled
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} label="Abha address" />
                    )}
                  />
                </Grid>
              )}
              {selectedAbhaModeOption === "link_abha" && (
                <Grid item xs={12} md={5}>
                  <TextField
                    label="ABHA Address"
                    name="abhaAddress"
                    value={
                      patientAbhaData?.preferredAbhaAddress ||
                      patientAbhaData[0]?.abha_address ||
                      patientAbhaData?.abha_address ||
                      patientAbhaData?.abhaAddress
                    }
                    onChange={handleChange}
                    required
                    disabled
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item xs={12} md={5}>
                <TextField
                  label="Address"
                  name="address"
                  value={patientAbhaData?.address || patientAddress}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  label="Pincode"
                  name="pincode"
                  value={
                    patientPincode ||
                    patientAbhaData?.pinCode ||
                    patientAbhaData?.pincode
                  }
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={abhaAddressError}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        ) : (
          <>
            <form onSubmit={handleComparedSubmit}>
              <Grid container spacing={2}>
                {/* Left Column (patientAbhaData) */}

                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={abdmChecked}
                        onChange={handleAbdmChange}
                        color="primary"
                      />

                      <Typography>Patient Details from ABDM</Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="First Name"
                        name="firstname"
                        value={
                          patientAbhaData?.firstName ||
                          patientAbhaData?.name?.split(" ")[0]
                        }
                        disabled
                        required
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Middle Name"
                        name="middlename"
                        value={
                          patientAbhaData?.middleName ||
                          patientAbhaData?.name?.split(" ")[1]
                        }
                        disabled
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Last Name"
                        name="lastname"
                        value={
                          patientAbhaData?.lastName ||
                          patientAbhaData?.name?.split(" ")[2]
                        }
                        disabled
                        required
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Email Address"
                        name="email"
                        value={patientAbhaData?.email}
                        disabled
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Mobile Number"
                        name="mobile"
                        value={
                          abhaNewMobile ||
                          patientAbhaData?.mobile ||
                          patientAbhaData?.mobile_number
                        }
                        disabled
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="DOB"
                        name="dob"
                        value={
                          patientAbhaData?.dob ||
                          patientAbhaData?.DOB ||
                          `${patientAbhaData?.dayOfBirth}-${patientAbhaData?.monthOfBirth}-${patientAbhaData?.yearOfBirth}`
                        }
                        disabled
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Gender"
                        name="gender"
                        value={patientAbhaData?.gender}
                        disabled
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="ABHA number"
                        name="abhaNumber"
                        value={
                          patientAbhaData?.ABHANumber ||
                          patientAbhaData?.abha_number ||
                          patientAbhaData?.abhaNumber
                        }
                        disabled
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="ABHA Address"
                        name="abhaAddress"
                        value={
                          patientAbhaData?.preferredAbhaAddress ||
                          patientAbhaData?.abha_address ||
                          patientAbhaData?.abhaAddress
                        }
                        disabled
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Address"
                        name="currentAddress" // Different name
                        value={currentAddress}
                        onChange={handleCurrentAddressChange} // Different handler
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        disabled={!abdmChecked}

                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Pincode"
                        name="currentPincode" // Different name
                        value={currentPincode}
                        onChange={handleCurrentPincodeChange} // Different handler
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        disabled={!abdmChecked}

                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* Right Column (comparedAbhaPatientData) */}
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {comparedAbhaPatientData && Object.keys(comparedAbhaPatientData).length > 0 && (
                        <>
                          <Checkbox
                            checked={registeredChecked}
                            onChange={handleRegisteredChange}
                            color="primary"
                            disabled={!comparedAbhaPatientData || Object.keys(comparedAbhaPatientData).length === 0}
                          /></>)}
                      {comparedAbhaPatientData && Object.keys(comparedAbhaPatientData).length > 0 ? (
                        <Typography>Registered Patient Details</Typography>
                      ) : (
                        <Typography>No registered patient found with the ABHA number</Typography>
                      )}
                    </Grid>
                    {comparedAbhaPatientData && Object.keys(comparedAbhaPatientData).length > 0 && (
                      <>
                        <Grid item xs={12} md={12}>
                          <TextField
                            label="First Name"
                            value={
                              comparedAbhaPatientData?.firstName ||
                              comparedAbhaPatientData?.name?.split(" ")[0]
                            }
                            disabled
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            label="Middle Name"
                            value={
                              comparedAbhaPatientData?.middleName ||
                              comparedAbhaPatientData?.name?.split(" ")[1]
                            }
                            disabled
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            label="Last Name"
                            value={
                              comparedAbhaPatientData?.lastName ||
                              comparedAbhaPatientData?.name?.split(" ")[2]
                            }
                            disabled
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            label="Email Address"
                            value={comparedAbhaPatientData?.email}
                            disabled
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            label="Mobile Number"
                            value={
                              comparedAbhaPatientData?.mobile ||
                              comparedAbhaPatientData?.mobile_number
                            }
                            disabled
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            label="DOB"
                            value={
                              comparedAbhaPatientData?.dob ||
                              comparedAbhaPatientData?.DOB ||
                              `${comparedAbhaPatientData?.dayOfBirth}-${comparedAbhaPatientData?.monthOfBirth}-${comparedAbhaPatientData?.yearOfBirth}`
                            }
                            disabled
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            label="Gender"
                            value={comparedAbhaPatientData?.gender}
                            disabled
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            label="ABHA number"
                            value={
                              comparedAbhaPatientData?.ABHANumber ||
                              comparedAbhaPatientData?.abha_number ||
                              comparedAbhaPatientData?.abhaNumber
                            }
                            disabled
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            label="ABHA Address"
                            value={
                              comparedAbhaPatientData?.preferredAbhaAddress ||
                              comparedAbhaPatientData?.abha_address ||
                              comparedAbhaPatientData?.abhaAddress
                            }
                            disabled
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            label="Address"
                            name="comparedAddress" // Different name
                            value={comparedAddress}
                            onChange={handleComparedAddressChange} // Different handler
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            disabled={!comparedAbhaPatientData || Object.keys(comparedAbhaPatientData).length === 0 || !registeredChecked}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            label="Pincode"
                            name="comparedPincode" // Different name
                            value={comparedPincode}
                            onChange={handleComparedPincodeChange} // Different handler
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            disabled={!comparedAbhaPatientData || Object.keys(comparedAbhaPatientData).length === 0 || !registeredChecked}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>

                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={abhaAddressError}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </>
    )
  );
};

export default AadhaarPatientRegForm;
