export const apis = {
  login: "v1/user/signIn",
  list: "v1/HIP/listAll",
  listHospitalByUser: "v1/HIP/listForUser",
  listPtaient: "v1/patient/listAll",
  registerAadhaar: "v1/HID/registration/aadhaar/generateOTP",
  verifyOTPAadhaar: "v1/HID/registration/aadhaar/verifyOTP",
  restigerNumber: "v1/registration/mobile/generateOtp",
  registerAadhaarNumber: "v1/HID/registration/aadhaar/generateMobileOTP",
  verifyOTPNumber: "v1/registration/mobile/verifyOtp",
  verifyAadhaarotp: "v1/HID/registration/aadhaar/verifyMobileOTP",
  registerUser: "v3/patient/register",
  registerAadhaarPaient: "v1/HID/registration/aadhaar/abhaRegistration",
  registerPhonePatient: "v1/registration/mobile/createHealthId",
  downloadAbhaCard: "v1/profile/getAbhaCard",
  displayAbhaCard: "v1/profile/getAbhaCardBytes",
  getAbhaCard: "v3/HID/retrieveAbha/getAbhaCard",
  listAllDoctors: "v1/listAllDoctors",
  doctorSlotsDetails: "v1/slots",
  createAppointment: "v1/appointment/create",
  listAppointments: "v1/appointment/listAll",
  searchVitals: "https://snomed.cliniq360.com/csnoserv/api/search/search",
  createEMR: "v2/PMR/createPMR",
  submitEMR: "v1/PMR/submitPMR",
  uploadPmrPdf: "v1/PMR/uploadPrescription",
  getVitalDetail: "v1/patient/getVitals",
  listConsent: "v1/HIU/listConsent",
  getConsentDetails: "v1/HIU/getConsentDetails",
  consentDetails: "v1/HIU/getCareContext",
  getPmrList: "v1/PMR/list",
  getPmrDocs: "v1/PMR/listDocuments",
  getDoc: "v1/PMR/getDocument",
  getDocBytes: "v1/PMR/getDocumentBytes",
  submitConsentReq: "v1/HIU/consentInit",
  fetchModes: "v1/patient/fetchModes",
  authInit: "v2/patient/auth/init",
  verifySyncOtp: "v1/patient/auth/verifyOtp",
  syncPMREndpoint: "v1/PMR/sync",
  patientDetails: "v1/patient",
  uploadHealthDocument: "v1/PMR/uploadHealthDocuments",
  pmrSendNotification: "v1/PMR/sendDocument",
  resetUserPassword: "v1/user/resetPassword",
  genearteOTPPassword: "v1/user/generateOTP",
  generateOTPAbha: "v3/HID/retrieveAbha/generateOTP",
  verifyOTPAbha: "v3/HID/retrieveAbha/verifyOTP",
  abhaRegistrationViaAadhaar: "v3/HID/registration/aadhaar/generateOTP",
  verifyAadhaarAbha: "v3/HID/registration/aadhaar/verifyOTP",
  suggestAbhaAddress: "v3/HID/registration/aadhaar/suggestAbha",
  createAbhaAddress: "v3/HID/registration/aadhaar/createAbhaAddress",
  aadhaarMobileGenerateOTP:
    "v3/HID/registration/updateProfileDetails/generateOTP",
  addhaarMobileVerifyOTP: "v3/HID/registration/updateProfileDetails/verifyOTP",
  registerAbhaPatient: "v3/patient/register",
  verifyAbhaUser: "v3/HID/retrieveAbha/verifyUser",
  getAbhaProfile: "v3/HID/retrieveAbha/getProfile",
  patientFetchModes: "v1/patient/fetchModes",
  patientAuthInit: "v3/patient/verifyABHA/generateOTP",
  patientAuthResendOtp: "v3/patient/auth/resendOtp",
  patientAuthVerifyOTP: "v3/patient/verifyABHA/verifyOTP",
  gatewayInteraction: "v1/gatewayInteraction",
  verifyAbhaPatient: "v1.0/patients/verify",
  verifyDemographics: "v1/patient/auth/verifyDemographic",
  demographicsAuthInit: "v2/patient/auth/init",
  deepLink: "v1/deepLinkNotify",
  searchAbha: "v1/HID/searchAbha",
  googleReview: "v1/PMR/sendGoogleReviewLink",
  listAppointmentByDate: "v1/appointment/listByDate",
  fetchPatientDetails: "v1/patient",
  appointmentAnalytics: "v1/appointmentAnalytics",
  previewPMR: "v1/PMR/previewPMR",
  recorderAnalysis: "v1/openAI/transcribe",
  updatePMRSummary: "v1/openAI/update-summary",
  previewSummary: "v1/PMR/preview-summary",
  uploadAudio: "v1/openAI/upload-audio",
  generateAiNotes: "v1/openAI/generate-ai-notes",
  addFeedback: "v1/openAI/add-feedback",
  deletePatient: "v1/patient/delete",
  getDoctorProfile: "v1/doctor-details/get-doctor-profile",
  sendAppointmentList: "v1/PMR/sendAppointementList",
  bookAppointment: "v1/register-patient/book-appointment",
  getDoctorDetails: "v1/doctor-details/get-by-id",
  registerDoctor: "v2/doctor-details/create",
  updateDoctor: "v1/doctor-details/update",
  createTemplate: "v1/template/create",
  getTemplateDetail: "v1/template-details",
  getAllTemplates: "v1/template-details/get-all",
  contactForm: "v1/leads/register",
  uploadProfileImage: "v2/doctor-details/profile-photo-signature-upload",
  getTemplateResponse: "v1/template-details-by-id",
  deleteTemplate: "v1/template/delete",
  updateTemplate: "v1/template/update",
  getFreqMedList: "v1/PMR/listMeds/freqUsed",
  readPatientByMobile: "v1/patient-leads/read-by-mobile",
  registerExternalPatient: "v1/patient-leads/register",
  listAllExternalPatient: "v1/patient-leads/listAll",
  fetchAbhaAddressAuthModes: "v3/patient/fetchModes",
  getAbhaByMobileNumber: "v3/HID/retrieveAbha/searchByMobile",
  getArtifactDetails: "v1/HIU/getArtifactDetails",
  findAbha: "v1/HIU/findPatient",
  getPatientAbhaDetails: "v1/patientByABHANumber",
};
