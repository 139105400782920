import { Button, Card, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import PdfFromDocumentBytes from "../../../PdfFromDocumentBytes";

const TableTitle = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primaryGrey,
  marginBottom: "1px",
  padding: theme.spacing(0, 5),
}));

const ReportCardSection = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(2),
}));

function AttachmentSection({ data }) {
  const [base64data, setBase64data] = useState(null);
  const [open, setOpen] = useState(false);
  const [documentType, setDocumentType] = useState(null);

  const handlePdfViewer = (base64Data, docType, isUrl) => async () => {
    try {
      if (isUrl) {
        try {
          window.location.href = base64Data;
        } catch (error) {
          console.error("Error handling PDF viewer:", error);
          return;
        }
      } else {
        setBase64data(base64Data);
        setDocumentType(docType);
        setOpen(true);
      }
    } catch (error) {
      console.error("Error handling PDF viewer:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!Array.isArray(data)) {
    console.error('Invalid prop: "data" is not an array.');
    return null;
  }
  return (
    <>
      <Typography variant="h6" fontSize={18} fontWeight={600}>
        # Attachements
      </Typography>
      <ReportCardSection>
        <TableTitle>
          <Typography variant="h6">Titles:</Typography>
        </TableTitle>
        <ul
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          {data?.map((item, index) => {
            // Check if the item is a DocumentReference
            if (item.resourceType === "DocumentReference") {
              return item.content.map((contentItem, subIndex) => (
                <Button
                  variant="text"
                  color="inherit"
                  key={`${index}-${subIndex}`}
                  onClick={handlePdfViewer(
                    contentItem?.attachment?.data || contentItem?.attachment?.url,
                    contentItem?.attachment?.contentType,
                    contentItem?.attachment?.url ? true : false
                  )}
                >
                  <li>{contentItem?.attachment?.title || "Untitled Document"}</li>
                </Button>
              ));
            }

            // Check if the item is a Binary
            if (item.resourceType === "Binary") {
              return (
                <Button
                  variant="text"
                  color="inherit"
                  key={index}
                  onClick={handlePdfViewer(
                    item?.data || item?.fullUrl,
                    item?.contentType
                  )}
                >
                  <li>{`Binary Document: ${item?.id || "Unknown"}`}</li>
                </Button>
              );
            }

            // Handle unexpected cases (fallback)
            return null;
          })}
        </ul>
      </ReportCardSection>
      {base64data && (
        <PdfFromDocumentBytes
          open={open}
          handleClose={handleClose}
          documentType={documentType}
          docBytes={base64data}
        />
      )}
    </>
  );
}
export default AttachmentSection;
