import { Card, Typography, styled } from "@mui/material";
import React from "react";

const TableTitle = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primaryGrey,
  marginBottom: "1px",
  padding: theme.spacing(0, 5),
}));

const ReportCardSection = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(2),
}));

function InvoiceSection({ ChargeItem, invoice }) {
  if (!Array.isArray(ChargeItem) || !Array.isArray(invoice)) {
    console.error('Invalid prop: "data" is not an array.');
    return null;
  }
  return (
    <ReportCardSection>
      <TableTitle>
        <Typography variant="h6">Invoice</Typography>
      </TableTitle>
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        {ChargeItem?.map((item, index) => (
          <div key={index}>
            <Typography variant="body1">
              <strong>Item Quantity:</strong> {item?.quantity?.value}
            </Typography>
            <Typography variant="body1">
              <strong>Item Name:</strong> {item?.productCodeableConcept?.text}
            </Typography>
          </div>
        ))}
        {invoice?.map((item, index) => (
          <div key={index}>
            {item?.lineItem?.[0]?.priceComponent?.map((subItem, subIndex) => (
              <Typography key={subIndex} variant="body1">
                <strong>{subItem?.code?.text}:</strong> {subItem?.amount?.value}{" "}
                {subItem?.amount?.currency}
              </Typography>
            ))}
            <hr />
            <Typography variant="body1">
              <strong>Total Net:</strong> {item?.totalNet?.value}{" "}
              {item?.totalGross?.currency}
            </Typography>
            <Typography variant="body1">
              <strong>Total Gross:</strong> {item?.totalGross?.value}{" "}
              {item?.totalGross?.currency}
            </Typography>
          </div>
        ))}
      </ul>
    </ReportCardSection>
  );
}
export default InvoiceSection;
